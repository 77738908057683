"use strict";
declare var grecaptcha;
declare function gtag(tag: string, action: string, parameters: object);
const lightboxTemplate = document.querySelector("#lightbox-template") as HTMLTemplateElement;
document.querySelectorAll("[data-lightbox]").forEach((link) => {
	link.addEventListener("click", function (event) {
		event.preventDefault();
		let fragment = lightboxTemplate.content.cloneNode(true) as DocumentFragment;
		let img = fragment.querySelector(".lightbox-image") as HTMLImageElement;
		img.src = this.href;
		document.body.appendChild(document.importNode(fragment, true));
		document.documentElement.classList.add("-locked");
		document.querySelector("#lightbox").addEventListener("click", function (event) {
			if (event.target == this) {
				this.remove();
				document.documentElement.classList.remove("-locked");
			}
		});
	});
});
const contact = document.forms.namedItem("contact");
function loadReCaptcha() {
	var recaptcha = grecaptcha.render("recaptcha-badge", {
		sitekey: "6Lca3KoZAAAAAMU018O2CEFcez5bczAwn0Ynq-eo",
		badge: "inline",
		size: "invisible",
	});
	grecaptcha.ready(function () {
		contact.addEventListener("submit", function (this: HTMLFormElement, event: Event) {
			event.preventDefault();
			let form = this;
			grecaptcha.execute(recaptcha, {
				action: "contact"
			}).then(function (token) {
				form.elements["recaptcha"].value = token;
				let controls = Array.from(form.elements) as Array<HTMLInputElement | HTMLTextAreaElement>;
				let status = document.getElementById("contact-status") || document.createElement("span");
				if (!document.contains(status)) {
					status.id = "contact-status";
					document.getElementById("contact-send").parentNode.appendChild(status);
				}
				status.innerHTML = "<strong>Sending&hellip;</strong> This may take a few seconds.";
				let xhr = new XMLHttpRequest();
				xhr.open(form.method, form.action);
				let handleResponse = function (event: Event) {
					if (xhr.status >= 200 && xhr.status <= 399) {
						gtag("event", "Send", {
							"event_category": "Email",
						});
						gtag('event', 'conversion', {
							'send_to': 'AW-791104465/NCMoCNupt_0BENGXnfkC',
						});
						console.debug(xhr.response);
						status.classList.remove("-error");
						status.classList.add("-success");
						status.innerHTML = "<strong>Thanks!</strong> We&rsquo;ll get back to you shortly.";
					} else {
						gtag("event", "Error", {
							"event_category": "Email",
							"event_label": xhr.status.toString(),
						});
						console.error(xhr.responseText);
						status.classList.add("-error");
						status.innerHTML = "<strong>Sorry!</strong> Something went wrong. Please try again.";
						controls.forEach(control => control.disabled = false);
					}
				};
				xhr.addEventListener("load", handleResponse);
				xhr.addEventListener("error", handleResponse);
				xhr.send(new FormData(form));
				controls.forEach(control => control.disabled = true);
				return false;
			});
		});
	});
};
Array.from(document.links).forEach(link => link.addEventListener("click", (event) => {
	if (link.origin === location.origin && link.pathname === location.pathname && link.search === location.search) {
		let target = link.hash ? document.getElementById(link.hash.substr(1)) : document.documentElement;
		if (target) {
			target.scrollIntoView({
				behavior: "smooth",
			});
			window.history.pushState(null, document.title, link.href);
			event.preventDefault();
		}
	}
}));
